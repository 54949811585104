import {useState, useEffect} from "react";
import './App.scss';
import { ThemeProvider } from '@material-ui/core';
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Navigation from "./components/Navigation";
import Copyright from "./components/Copyright";
import PropertiesList from "./components/properties/PropertiesList";
import {theme, darkTheme, buildTheme} from "./themes/eptheme";
import Confetti from "./utils/Confetti"
import { Route, BrowserRouter, useHistory, Switch } from "react-router-dom";

import UserContext from "./contexts/UserContext";
import NewPropertyForm from "./components/properties/NewPropertyForm";
import PrivateRoute from "./utils/PrivateRoute";
import StripeContainer from "./components/stripe/StripeContainer";
import CheckToken from "./components/CheckToken";
import Quickbooks from "./components/quickbooks/Quickbooks"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ConfirmEmail from "./components/ConfirmEmail";
import RequestEmail from "./components/request-emails/RequestEmail";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SourceBillFiles from "./components/SourceBillFiles"
import TextractContainer from "./components/textract/TextractContainer";
import BillingPortfolios from "./components/billing-portfolios/BillingPortfolios";
import BillableCompaniesList from "./components/billing-portfolios/BillableCompaniesList"

import Widgets from "./components/widgets/Widgets"

function App() {
  const [user, setUser] = useState({loggedIn: !!localStorage.getItem("jwt")})
  const [properties, setProperties] = useState([])
  const [activeTheme, setActiveTheme] = useState(theme)
  const [selectedTemplate, setSelectedTemplate] = useState({name: "", subject: "", body: ""})
  const [widgetOptions, setWidgetOptions] = useState({})
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const darkMode = false;
  useEffect(_ => setActiveTheme(user && user.loggedIn && (true || user.admin) ? buildTheme({prefersDarkMode}) : theme), [user]) // TODO: Make this dynamic on embeds
  return (
    <ThemeProvider theme={activeTheme}>
      <UserContext.Provider value={{widgetOptions, setWidgetOptions, activeTheme, setActiveTheme, user, setUser, properties, setProperties, selectedTemplate, setSelectedTemplate }}>
        <div key={`${activeTheme.palette.mode}-mode`} className={`${activeTheme.palette.mode}-mode`}>
          <Confetti />
          <BrowserRouter>
            <CheckToken />
            <Switch>
              <Route path="/widgets">
                <Widgets />
              </Route>
              <Route>
                <div className="App">
                  <Navigation />
                  <PrivateRoute exact path="/" component={StripeContainer} />
                  <PrivateRoute exact path="/request" component={RequestEmail} />
                  <PrivateRoute exact path="/source_bill_files" component={SourceBillFiles} />
                  <PrivateRoute exact path="/quickbooks" component={Quickbooks} />
                  <PrivateRoute path="/invoyage" component={TextractContainer} />
                  <Route exact path="/confirm" component={ConfirmEmail} />
                  <Route path="/login" component={SignIn} />
                  <Route path="/signup" component={SignUp} />
                  <Route exact path="/checkout" component={StripeContainer} />
                  <PrivateRoute exact path="/properties" component={PropertiesList} />
                  <PrivateRoute exact path="/properties/new" component={NewPropertyForm} />
                  <Copyright />
                </div>
              </Route>
            </Switch>
          </BrowserRouter>
        </div>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
